import React from "react";
import { Container, Row, Col, Table } from "react-bootstrap";

const Prices = (props, ref) => (
  <div className="prices page" ref={ref}>
    <Container>
      <Row>
        <Col xs={12} md={12}>
          <h2 className="title text-center">Cennik</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <Table responsive>
            <thead>
              <tr>
                <th>Usługa</th>
                <th>Cena</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Pierwsze spotkanie diagnostyczne (60 min.)</td>
                <td>200 zł</td>
              </tr>
              <tr>
                <td>Kolejne spotkania - psychoterapia/poradnictwo/konsultacje (50 min.)</td>
                <td>180 zł</td>
              </tr>
              <tr>
                <td>Sporządzenie opinii psychologicznej</td>
                <td>180 zł</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  </div>
);

export default React.forwardRef(Prices);
